import {Container, createTheme, Grid, IconButton, Link, Snackbar, Stack, ThemeProvider} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import "dayjs/locale/pl"
import CloseIcon from '@mui/icons-material/Close';
import MessageContext from "./MessageContext";
import {plPL} from "@mui/x-date-pickers/locales";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import {useKeycloak} from "@react-keycloak/web";
import BookList from "./components/BookList";
import SingleBook from "./pages/SingleBook";
import NewComment from "./pages/NewComment";
import {initialProfile, ProfileContext} from "./ProfileContext";
import MyBookList from "./components/MyBookList";
import AddBook from "./pages/AddBook";
import PageSoon from "./pages/PageSoon";
import Regulamin from "./pages/Regulamin";

export default function () {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const {keycloak, initialized} = useKeycloak();

    const [profile, setProfile] = useState(initialProfile);
    useEffect(() => {
        if(initialized && keycloak.authenticated) {
            keycloak.loadUserProfile().then(p => {
                setProfile(p as any);
            })
        }
    }, [initialized])

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    useEffect(() => {
        if (message) {
            setOpen(true)
        }
    }, [message]);

    return (
        <ProfileContext.Provider value={profile}>
            <ThemeProvider theme={createTheme({}, plPL)}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                    <MessageContext.Provider value={setMessage}>
                        <Snackbar
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message={message}
                            action={action}
                        />
                        <header>
                            <div className="logoSection"><span><Link href="/">Świętej pamięci...</Link></span></div>
                            <div className="breakSection"></div>
                            <div className="sloganSection"><span>Księgi pamiątkowe ze świadectwami o naszych bliskich, którzy - choć
                odeszli do Pana - dalej trwają w naszej pamięci.</span></div>
                        </header>
                        <div id="content">
                            <Router>
                                <Routes>
                                    <Route path="/" element={<HomePage/>}/>
                                    {/*<Route path="/login" component={LoginPage} />*/}
                                    <Route path="/ksiegi" element={<BookList/>}/>
                                    <Route path="/moje-ksiegi" element={<MyBookList/>}/>
                                    <Route path="/dodaj-ksiege" element={<AddBook/>}/>
                                    <Route path="/ksiegi/:url" element={<SingleBook/>}/>
                                    <Route path="/ksiegi/:url/dodaj-wpis" element={<NewComment/>}/>
                                    <Route path="/o-stronie" element={<PageSoon/>}/>
                                    <Route path="/regulamin" element={<Regulamin/>}/>
                                    <Route path="/polityka-prywatnosci" element={<PageSoon/>}/>
                                    <Route path="/kontakt" element={<PageSoon/>}/>
                                </Routes>
                            </Router>
                        </div>

                        <footer>
                            <Container>
                                <Grid container className="footerWrapper" spacing={2}>
                                    <Grid item md={5} xs={12}>Świętej pamięci...</Grid>
                                    <Grid item md={4} xs={12}>
                                        <Stack spacing={2}>
                                            <Link href="/o-stronie">O stronie</Link>
                                            <Link href="/ksiegi">Wyszukiwanie ksiąg</Link>
                                            <Link href="/regulamin">Regulamin</Link>
                                            <Link href="/polityka-prywatnosci">Polityka prywatności</Link>
                                            <Link href="#" onClick={e => keycloak.login()}>Logowanie</Link>
                                            <Link href="/kontakt">Kontakt</Link>
                                        </Stack>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <p>Patronat honorowy:</p>
                                        <p><a className="footer-link" href="https://katecheza.episkopat.pl/" target="_blank">Komisja Wychowania Katolickiego Konferencji Episkopatu Polski</a></p>
                                        <hr />
                                    <p>Stroną opiekuje się:</p> <a href="https://tuespetrus.pl/"><img src="/logo.png"/></a>
                                    </Grid>
                                </Grid>
                            </Container>
                        </footer>
                    </MessageContext.Provider>
                </LocalizationProvider>
            </ThemeProvider>
        </ProfileContext.Provider>
    )
}