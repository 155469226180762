import {Button, Card, Stack} from "@mui/material";
import React, {useContext} from "react";
import './BookCommentToAccept.scss'
import axios from "axios";
import {useKeycloak} from "@react-keycloak/web";
import MessageContext from "../MessageContext";

export default function BookCommentToAccept(props: { comment: any, reload: any }) {
    const setMessage = useContext(MessageContext);
    const {keycloak, initialized} = useKeycloak();

    function accept() {
        axios.put(process.env.REACT_APP_MEMENTO_SERVER_URL + "api/v1/comments/" + props.comment.id, {}, {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token,
            }
        }).then(d => {
            setMessage("Wpis zaakceptowano");
            props.reload();
        }).catch(d => setMessage("Błąd podczas modyfikacji wpisu"));
    }

    function reject() {
        axios.delete(process.env.REACT_APP_MEMENTO_SERVER_URL + "api/v1/comments/" + props.comment.id, {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token,
            }
        }).then(d => {
            setMessage("Wpis odrzucono");
            props.reload();
        }).catch(d => setMessage("Błąd podczas modyfikacji wpisu"));
    }

    return (
        <Card className="BookCommentWrapper">
            {props.comment.photoPath && <img src={process.env.REACT_APP_MEMENTO_SERVER_URL + "api/images/" + props.comment.photoPath}/>}
            <h2>{props.comment.bookName}</h2>
            <h3>{props.comment.bookTitle}</h3>
            <p>({props.comment.signature}, {props.comment.commentDate})</p>
            <p>{props.comment.content}</p>
            <Stack spacing={2} direction="row">
                <Button variant="outlined" color="success" onClick={accept}>Akceptuj</Button>
                <Button variant="outlined" color="error" onClick={reject}>Odrzuć</Button>
            </Stack>
        </Card>
    )
}