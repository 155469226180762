import {Card} from "@mui/material";
import React from "react";
import './BookComment.scss'

export default function BookComment(props: {comment: any}) {
    return (
        <Card className="BookCommentWrapper">
            <h3>{props.comment.title}</h3>
            <p>({props.comment.signature}, {props.comment.commentDate})</p>
            {props.comment.photoPath && <img src={process.env.REACT_APP_MEMENTO_SERVER_URL + "api/images/" + props.comment.photoPath}/>}
            <p>{props.comment.content}</p>
        </Card>
    )
}