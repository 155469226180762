import {Card, Grid, Stack} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

export default function BookListElement(props: { book: any }) {
    return (
        <Link to={"/ksiegi/" + props.book.url}>
            <Card className="BookListElementWrapper">
                <Grid container spacing={2} alignItems="center" columns={17}>
                    <Grid item md={2} xs={17}>
                        <img src={process.env.REACT_APP_MEMENTO_SERVER_URL + "api/images/" + props.book.photoPath}/>
                    </Grid>
                    <Grid item md={15} xs={17}>
                        <span>{props.book.name}</span>
                    </Grid>
                </Grid>
            </Card>
        </Link>
    )
}