import React, {useEffect, useState} from "react";
import {Button, Container, Divider, Grid, Stack} from "@mui/material";
import './SingleBook.scss'
import axios from "axios";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import BookComment from "../components/BookComment";
import ReactGA from "react-ga4";

export default function KsiegaPage() {
    let navigate = useNavigate();
    let location = useLocation();
    const [currentUrl, setCurrentUrl] = useState("");
    const {url} = useParams() as { url: string }
    const [book, setBook] = useState({photoPath: "", name: "", content: "", comments: [], introduction: ""})


    useEffect(() => {
        if (book.name) {
            document.title = book.name + ' - Księga pamiątkowa - sp.katolicki.pl';
            ReactGA.send(location.pathname + location.search);
        }
    }, [book]);


    useEffect(() => {
        if(url.length > 0 && url !== currentUrl) {
            setCurrentUrl(url)
            axios.get(process.env.REACT_APP_MEMENTO_SERVER_URL + "api/v1/books/" + url).then(
                d => setBook(d.data)
            ).catch(
                d => navigate("/404")
            )
        }
    }, [url]);



    return (
        <>
            <div className="PageHeader">
                <Container>
                    <h2>Przeglądanie księgi</h2>

                </Container>
            </div>
            <Container className="SingleBookWrapper">
                <Grid container spacing={3} className="SingleBookHeader">
                    <Grid item md={2} xs={12}>
                        <img src={process.env.REACT_APP_MEMENTO_SERVER_URL + "api/images/" + book.photoPath}/>
                    </Grid>
                    <Grid item md={10} xs={12}>
                        <h3>{book.name}</h3>
                        <p>{book.introduction}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end">
                            <Button variant="outlined" onClick={() => {navigate(location.pathname + '/dodaj-wpis')}}>Dodaj wpis</Button>
                        </Stack>
                    </Grid>
                </Grid>
                <Divider color="#004AAD"/>
                <Container sx={{mt:2}}>
                    {(!book.comments || book.comments.length <= 0) && <h3>Brak wpisów</h3>}
                    {(book.comments && book.comments.length > 0) && book.comments.map(c => {return (<BookComment comment={c}/>)})}
                </Container>
            </Container>
        </>
    )
}